.next-box {
    text-align: end;
    padding: 1.5rem 1rem;
}

.next-box > div, .submit-button {
    background-color: rgb(19, 27, 71);
    padding: 10px 45px !important;
    font-weight: 600;
    color: white;
    display: inline-block;
    border-radius: 8px;
    cursor: pointer;
}

.mini-header {
    color: #22C55E;
}

.non-activelink {
    color: gray;
}

.idactivelink {
    color: #0074CC;
    text-decoration: underline;
    cursor: pointer;
}

.input-group {
    justify-content: center;
}

.health-form > div > div {
    padding: 0.5rem 0rem;
}

.preview-group > div {
    width: 28%;
}

.underwriter-group {
    padding: 1rem 0rem;
}

.health-form {
    padding: 0.75rem;
}

.dot {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #D9D9D9;
    display: block;
}

.dot-container {
    padding: 1rem 0rem;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.dot-active {
background-color: #22C55E !important;
}

.submit-box {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.submit-back {
    font-weight: 700;
    cursor: pointer;
    font-size: 18px;
}

.upload-input > div > input {
    border: 0px;
}

.quotaion-title {
    width: 97%;
    margin: auto;
    border-bottom: 1px solid rgba(228,228,231, 1);
}

.quotaion-title > h6 {
    font-weight: 700;
    font-size: 1.125rem;
    /* line-height: 1.75rem; */
}

.page-control {
    display: flex;
    justify-content: space-between;
    width: 97%;
    margin: auto;
    z-index: 1;
    position: relative;
}

.page-control > span {
    align-self: center;
    font-weight: 700;
    font-size: 18px;
    color: black;
    cursor: pointer;
}

.underwriter-group > div{
    width: 30%;
}

.underwriter-group > div > label, .quote-group > div > label, .personal-group > div > label, .personal-single > div > label{
    font-size: 16px;
}

.personal-group, .personal-single {
    padding: 0.75rem 0rem !important;
}

.quote-group {
    padding: 1.5rem 0rem !important;
}

.quote-group > div {
    width: 22%;
}

.flood-section {
    width: 97%;
    margin: auto;
    border-top: 1px solid rgba(228,228,231, 1);
    padding: 1rem !important;
}

.flood-radio > label {
    padding: 0rem 0.5rem;
    font-weight: 700;
    font-size: 18px;
}

.flood-section > h6 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
}

.total-premium > label {
    color: #22C55E;
}

.total-premium > p {
    font-weight: 800;
    font-size: 1.5rem !important;
}

.personal-info-form {
    display: flex;
    width: 97%;
    margin: auto;
    gap: 2rem;
    border-top: 1px solid rgba(228,228,231, 1);
}

.non-active {
    opacity: .3;
}

.personal-info-form > div {
    width: 47%;
}

.personal-group > div {
    width: 45%;
}

.personal-single > div {
    width: 97%;
}

.fileinput {
    color: rgba(34,197,94, 1);
    background-color: rgba(220,252,231,1);
    font-weight: 600;
    border-radius: 20px;
    padding: 5px 10px;
}

.filename {
    font-size: 14px;
    padding: 0px 10px;
}

.mobile-show {
    display: none !important;
}

.xs-mobile-show {
    display: none !important;
}

.tandcbutton {
    font-size: 14.5px;
}

.tandcbutton > button {
    color: rgba(59,130,246, 1);
    border: 0px;
    background-color: white;
}

.terms-header > button {
    color: red !important;
    background: transparent url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23f00%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>') !important;
}

.transfer-group {
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    color: #011b33;
    padding: 16px;
    width: 100%;
    font-size: 1.4rem;
    background: #f3f3f3;
    width: 97%;
    margin: 1rem auto;
}

.transfer-group > div > label {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #343c43;
    text-transform: uppercase;
}

.transfer-group > div > p, .transfer-group > div > div > p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 0 0;
    color: #323d4b;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0rem;
}

.transfer-pair {
    display: flex;
    justify-content: space-between;
}

.transfer-pair > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 5px  ;
    color: #323d4b;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #22C55E;
    cursor: pointer;
}

.upload-container {
    width: 97%;
    margin: 1rem auto;
}

.show-acct {
    font-weight: 600;
    cursor: pointer;
    color: black;
}

.show-acct:hover {
    color: grey;
}

.transfer-instruct {
    font-size: 12px;
    font-weight: 400;
    color: gray;
    width: 97%;
    margin: auto;
}

.hmo_resend {
    color: #0074CC;
    text-decoration: underline;
    cursor: pointer;
}


@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {
 
}

@media only screen and (max-width: 992px) {
    
}

@media only screen and (max-width: 820px) {
    .quote-group > div {
        width: 30%;
    }

    .input-group > div > label, .form-tab > div > label {
        display: block;
        font-size: 15px;
    }

    .mobile-none {
        display: none !important;
    }

    .mobile-show {
        display: block !important;
    }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .input-group {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 500px) {
    .health-form {
        padding: 1rem;
    }
    
    .quote-group > div {
        width: 47%;
    }

    .xs-mobile-none {
        display: none !important;
    }

    .xs-mobile-show {
        display: flex !important;
    }

    .flood-section > h6 {
        font-size: 0.85rem;
    }

    .flood-section {
        padding: 1rem 0rem !important;
    }

    .input-group > div > label, .form-tab > div > label {
        display: block;
        font-size: 12px;
    }

    .flood-radio > label {
        font-size: 12px;
    }

    .page-control > span {
        font-size: 15px;
    }

    .personal-group > div {
        width: 100%;
    }

    .filename {
        font-size: 9px;
        display: block;
        padding: 8px 10px;
    }
}