.claims-containers {
    padding: 4rem 4rem 0rem;
}

.claims-table-container {
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    margin: 2rem 0rem;
}

.claims-table-container > table > thead > tr > th {
    padding: 15px;
}

.table-content > tr > td {
    padding: 15px !important;
}

table {
    margin: 0px !important;
}

.no-data-table {
    padding: 6rem !important;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    border: 0px;
}

.open > .maincontent > .claims-containers > .claims-table-container {
    margin: 4rem 4rem;
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {
    .claims-table-container {
        margin: 2rem 0rem;
        font-size: 14px;
    }

    .open > .maincontent > .claims-containers > .claims-table-container {
        margin: 4rem 0rem;
    }

    .claims-containers {
        padding: 4rem 2rem 0rem;
    }
}

@media only screen and (max-width: 992px) {
    .claims-table-container > table > thead > tr > th {
        padding: 10px;
    }

    .claims-containers {
        padding: 1rem 2rem 0rem;
    }
}

@media only screen and (max-width: 768px) {
    .claims-table-container {
        overflow: auto;
    }
}

@media only screen and (max-width: 600px) {
    .claims-table-container {
        margin: 2rem 0rem;
        font-size: 11px;
    }

    .claims-containers {
        padding: 0rem 1rem 0rem;
    }

}

@media only screen and (max-width: 500px) {
    .claims-table-container {
        margin: 2rem 0rem;
        font-size: 11px;
        /* width: max-content; */
    }
}