.wallet-container {
    padding: 4rem 4rem;
}

.wallet-display-grid {
    display: grid;
    width: 75%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
    gap: 20px;
    padding: 1.5rem 0rem;
    /* margin: 0rem 4rem; */
}

.wallet-display-grid > div {
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    padding: 15px 15px;
}

.wallet-card > h4 {
    font-size: 17px;
    font-weight: 700;
    margin: 0px;
    padding-bottom: 2.7rem;
}

.wallet-card > p {
    margin: 0px;
    font-size: 21px;
    font-weight: 700;
}

.wallet-tab-container {
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    /* margin: 0rem 4rem; */
}

.wallet-tab-title {
    display: flex;
    justify-content: center;
    gap: 20px;
    border-bottom: 1px solid #DDDDDD;
}

.wallet-tab-title > div {
    margin: 0rem;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 10px 10px;
    cursor: pointer;
}

.wallet-tab-body {
    padding: 2rem 4rem;
}

.wallet-input > label {
    display: block;
    font-size: 17px;
    font-weight: 600;
    padding-bottom: 0.5rem;
}

.wallet-input > input, .wallet-input > select {
    width: -webkit-fill-available;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    height: 37px;
    padding: 5px 10px;
}

.wallet-input {
    padding: 1rem 0rem;
}

.wallet-input > input:focus-visible, .wallet-input > select:focus-visible {
    outline: 0px;
}

.wallet-form {
    width: 45%;
    margin: auto;
}

.wallet-button {
    text-align: center;
    z-index: 99;
    position: sticky;
    padding: 1.5rem 0rem 2rem;
}

.wallet-button > button {
    border: 0px;
    border-radius: 4px;
    padding: 10px 25px;
    background-color: rgb(19, 27, 71);
    font-weight: 700;
    color: white;
}

.open > .maincontent > .wallet-container > div > .wallet-display-grid {
    width: 85%;
}

.wallet-add-button {
    padding-bottom: 0rem;
}

@media only screen and (max-width: 1280px) {
    .wallet-display-grid {
        display: grid;
        width: 86%;
    }
}

@media only screen and (max-width: 1024px) {
    .wallet-display-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
        margin: 0rem;
    }

    .wallet-card > h4 {
        font-size: 12px;
        padding-bottom: 1.7rem;
    }

    .wallet-card > p {
        font-size: 14px;
    }

    .wallet-tab-title > div {
        margin: 0rem;
        font-size: 14px;
    } 
    
    .wallet-container {
        padding: 4rem 2rem;
    }

    .wallet-tab-body {
        padding: 1rem 1rem;
    }
}

@media only screen and (max-width: 992px) {
    .wallet-form {
        width: 65%;
    }

    .wallet-container {
        padding: 1rem 2rem;
    }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .wallet-container {
        padding: 0rem 1rem;
    }

    .wallet-tab-container {
        margin: 0rem 0rem;
    }

    .wallet-form {
        width: 85%;
    }

    .wallet-input > label {
        font-size: 15px;
    }

    .wallet-input {
        padding: 0.75rem 0rem;
    }

    .wallet-input > input, .wallet-input > select {
        font-size: 14px;
    }

    .wallet-display-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 240px));
    }
}

@media only screen and (max-width: 500px) {
    .wallet-tab-title > div {
        margin: 0rem;
        font-size: 13px;
        padding: 15px 7px 7px;
    }

    .wallet-tab-title {
        gap: 12px;
    }
    
    .wallet-tab-body {
        padding: 2rem 1rem;
    }

    .wallet-display-grid {
        width: 90%;
    }
}