:root {
    --fontfamily: 'Nunito';
        font-display: swap
  }
  
  p {
    font-family: var(--fontfamily);
  }
  a {
    font-family: var(--fontfamily);
  }
  div {
    font-family: var(--fontfamily);
  }
  span {
    font-family: var(--fontfamily);
  }
  