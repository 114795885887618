.customer-container {
    padding: 4rem 4rem 0rem;
    position: relative;
}

.customer-modal {
    box-shadow: 0px 4px 15px rgba(11, 34, 57, 0.13);
    border-radius: 8px;
    margin: 4rem 0rem;
    z-index: 99;
}

.customer-modal-title {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #DDDDDD ;
    gap: 4.5rem;
}

.customer-modal-title > div {
    display: flex;
}

.customer-modal-title > div > svg {
    margin: auto;
}

.customer-modal-title > div > p {
    margin: 0rem;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 10px 10px;
    cursor: pointer;
}

.customer-modal-body {
    padding: 2rem 0.5rem;
}

.activetab {
    border-bottom: 4px solid rgb(19, 27, 71);
    border-radius: 4px;
}

.customer-list {
    border: 1px solid #dddd;
    border-radius: 8px;
    border-collapse: inherit;
    margin-bottom: 0rem;
}

.dropdown-toggle:after {
    border-bottom: 0;
    border-top: 0.3em solid;
    content: "";
    display: inline-block;
    margin-left: 0.255em;
}

.customer-search {
    padding: 1rem 0rem 0rem !important;
}

.upload-input-div > input {
    background-color: #ffffff !important;
}

.add-customer-card > h4 {
    align-self: center;
}

.customer-list > thead, .customer-list > tbody, .customer-list > tbody > tr > td, .customer-list > thead > tr > th, .customer-list > thead,  .customer-list > thead > tr, .customer-list > tbody, .customer-list > tbody > tr   {
    border-width: 1px;
}

.input-group > div > label, .form-tab > div > label {
    display: block;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
}

.input-group-claims {
    padding: 10px 0px;
}

.input-group-claims > div > label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
}

.claim-sub-topic {
    font-size: 18px;
    padding: 0px 15px;
    font-weight: 700;
}

.form-tab {
    width: 85%;
    margin: 1rem auto;
}

.input-group {
    gap: 2rem;
}

.input-group > div {
    width: 47%;
}

.input-group > div > input, .input-group > div > select {
    border: 0px;
    background-color: #e2f5ec;
    border-radius: 4px;
    height: 40px;
    color: black;
    width: -webkit-fill-available;;
    padding: 0px 10px;
    font-weight: 600;
}

.input-group > div > textarea {
    border: 0px;
    background-color: #e2f5ec;
    border-radius: 4px;
    width: -webkit-fill-available;;
    padding: 10px 10px;
    font-weight: 600;
}


.input-group > div > input:focus-visible, .input-group > div > select:focus-visible {
    outline: 0;
    color: black;
}

.form-tab > div > textarea {
    width: -webkit-fill-available;
    border: 0px;
    border-radius: 4px;
    padding: 0px 10px;
    background-color: #e2f5ec;
}

.form-tab > div {
    padding: 0.5rem;
}

.customer-button {
    text-align: center;
}

.customer-button > button {
    border: 0px;
    background-color: rgb(19, 27, 71);
    color: white;
    font-weight: 600;
    border-radius: 4px;
    padding: 10px 15px;
    z-index: 99;
    position: sticky;
}



.buy-policy {
    background-color: rgb(19, 27, 71) !important;
    font-weight: 700 !important;
    color: white !important;
}

.open > .maincontent > .customer-container > .customer-modal {
    margin: 2rem 0rem;
}

@media only screen and (max-width: 1280px) {
    .open > .maincontent > .customer-container > .customer-modal {
        margin: 2rem 0rem;
    }

    .customer-modal {
        box-shadow: 0px 4px 15px rgba(11, 34, 57, 0.13);
        border-radius: 8px;
        margin: 2rem 0rem;
    } 
    
    .input-group-claims > div {
        width: 45%;
    }
}

@media only screen and (max-width: 1024px) {
    .customer-modal {
        box-shadow: 0px 4px 15px rgba(11, 34, 57, 0.13);
        border-radius: 8px;
        margin: 1rem 0rem;
    }

    .customer-list {
        font-size: 13px;
    }

    .buy-policy {
        font-size: 13px !important;
    }

    .customer-container {
        padding: 4rem 2rem 0rem;
    }

    .add-customer-card > svg {
        width: 50px;
        height: 50px;
    }

    .customer-modal-body {
        padding: 1rem 0.5rem;
    }

    .lists-cust {
        font-size: 14px;
    }
}

@media only screen and (max-width: 992px) {
    .customer-modal-body {
        padding: 2rem 2rem;
    }

    .modal {
        --bs-modal-width: 700px !important;
    }

    .customer-container {
        padding: 1rem 2rem 0rem;
    }

    .claim-sub-topic {
        font-size: 16px;
        padding: 0px 15px;
        font-weight: 700;
    }
}

@media only screen and (max-width: 820px) {
    .customer-modal-body {
        padding: 2rem 1rem;
    }

    .customer-container {
        padding: 1rem 2rem 0rem;
        position: relative;
    }
}

@media only screen and (max-width: 768px) {
    .customer-list {
        font-size: 11px;
    }

    .customer-modal-body {
        padding: 2rem 1rem;
    }

    .open > .maincontent > .customer-container > .customer-modal {
        margin: 4rem 0rem;
    }

    .input-group-claims-sm > div {
        width: 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    .customer-container {
        padding: 0rem 1rem 0rem;
    }

    .customer-modal {
        margin: 1rem 0rem 2rem;
        position: relative;
    }

    .customer-list > tbody {
        overflow-wrap: anywhere;
    font-size: 9px;
    }

    .customer-list > thead, .customer-list > tbody, .customer-list > tbody > tr > td, .customer-list > thead > tr > th, .customer-list > thead, .customer-list > thead > tr, .customer-list > tbody, .customer-list > tbody > tr {
        border-width: 1px;
        padding: 4px !important;
    }

    .buy-policy {
        font-size: 10px !important;
        padding: 3px !important;
    }

    .input-group {
        gap: 1rem;
    }

    .input-group > div > label, .form-tab > div > label {
        display: block;
        font-size: 11px !important;
        font-weight: 600;
        padding-bottom: 5px;
    }

    .input-group > div > input, .input-group > div > select {
        width: -webkit-fill-available;
        border: 1px solid #dddd;
        border-radius: 4px;
        height: 29px;
        padding: 0px 10px;
        font-size: 12px;
    }

    .form-tab {
        width: 95%;
    }

    .input-group > div {
        width: 47%;
    }

    .healthdropdown {
        font-size: 12px !important;
        padding: 3px 10px !important;
    }

    .customer-modal-body {
        padding: 0rem 0.5rem;
    }

    .lists-cust {
        font-size: 11px;
    }

    .claim-sub-topic {
        font-size: 12px;
        padding: 0px 0px;
        font-weight: 700;
    }
}

@media only screen and (max-width: 500px) {
    .customer-modal-title {
        gap: 1.5rem;
    }

    .customer-modal-title > div > p {
        margin: 0rem;
        font-size: 13px;
    }
    
    .customer-modal-title > div > svg {
        margin: auto;
        width: 15px;
    }

    .customer-modal-body {
        padding: 0rem 0.5rem;
    }

    .buy-policy {
        font-size: 10px !important;
        padding: 3px !important;
    }

    .customer-list > thead, .customer-list > tbody, .customer-list > tbody > tr > td, .customer-list > thead > tr > th, .customer-list > thead, .customer-list > thead > tr, .customer-list > tbody, .customer-list > tbody > tr {
        border-width: 1px;
        padding: 4px !important;
    }

    .lists-cust {
        font-size: 10px;
        overflow-wrap: anywhere;
    }
    
}