.layout {
    display: grid;
    width: 100vw;
    grid-template-columns: 100px auto;
    height: 100vh;
    transition: 400ms;
}

.open {
    grid-template-columns: 250px auto;
}

.sidebar {
    background-color: #00A859;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

/* .close {
    visibility: hidden;
    transition: width 0.5s ease;
} */

.maincontent {
    position: relative;
    overflow: auto;
    overflow-x: hidden;
}

.maincontent > img {
    position: absolute;
    right: -10%;
    overflow-x: hidden;
    display: inline-block;
    max-width: 100%;
    height: auto;
    top: -25%;
}

.footer-layer1 {
    position: relative;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.footer-container {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.footer-layer1 > svg {
    fill: #00a859;
    opacity: 0.05;
    position: relative;
    display: block;
    width: calc(144% + 1.3px);
    height: 80px;
}

.footer-layer2 {
    position: relative;
    top: -55px;
    margin-bottom: -55px;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.footer-layer2 > svg {
    fill: #00a859;
    opacity: 0.05;
    position: relative;
    display: block;
    width: calc(144% + 1.3px);
    height: 80px;
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 992px) {
    .layout {
        grid-template-columns: 0px auto;
    }
    
    
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .layout {
        grid-template-columns: 0px auto;
    } 

}

@media only screen and (max-width: 500px) {
 
}