.navbar-container {
    display: none;
}

.navbar-container > div > img {
    max-width: 100%;
    /* margin-top: -12%;
    margin-left: 13%; */
}

.navbar-container > h4 {
    padding: 2.5rem 0rem 0rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {
 
}

@media only screen and (max-width: 992px) {
    .navbar-container {
    display: flex;
    padding: 1rem 2rem;
    justify-content: space-between;
}

    .navbar-title {
        display: flex;
        gap: 2rem;
    }

    .navbar-title > h4 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 700;
        color: #22C55E;
        margin: 0px;
    }

    .navbar-title > svg {
        align-self: center;
    }

}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .navbar-container {
        padding: 1rem 1rem;
    }

    .navbar-title {
        display: flex;
        gap: 1rem;
    }

    .navbar-title > svg {
        width: 30px;
        height: 30px;
    }

    .navbar-title > h4 {
        font-size: 1.35rem;
    }
}

@media only screen and (max-width: 500px) {
    .navbar-container {
        padding: 1rem 1rem;
    }
}

