.dashboard-container {
    padding: 4rem 4rem 0rem;
}

.dashboard-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    color: #22C55E;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 1.5rem 0rem;
}

.dashboard-card {
    display: flex;
    justify-content: space-around;
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    padding: 15px 0px;
}

.dashboard-card > div > p {
    margin: auto;
    padding: 1rem 0rem 0rem;
    font-size: 22px;
    font-weight: 600;
}

.dashboard-card > div > h4 {
    font-weight: 600;
}

.dashboard-card > svg {
    align-self: center;
}

.dashboard-table > h4 {
    font-size: 1.15rem;
    line-height: 2rem;
    font-weight: 700;
    color: #22C55E;
}

.dashboard-list-container {
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    padding: 15px 15px;
}

td {
    padding: 1rem 0.5rem !important;
    font-weight: 600;
}

.dashboard-table {
    padding: 3rem 0rem;
}

.dashboard-list-container {
    margin: 1.5rem 0rem;
}

.filteredTransactionSelect {
    border: 0px;
    background-color: #e2f5ec;
    /*border-radius: 4px;*/
    height: 40px;
    color: black;
    width: -webkit-fill-available;
    padding: 0px 10px;
    font-weight: 600;
}

.filteredTransactionSelect:focus-visible{
    outline: none;
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {
    .dashboard-list-container {
        font-size: 14px;
    }

    .dashboard-container {
        padding: 4rem 2rem 0rem;
    }

    .dashboard-card > div > h4 {
        font-weight: 600;
        font-size: 18px;
    }

    .dashboard-card > svg {
        width: 50px;
        height: 50px;
    }
    
    .dashboard-card > div > p {
        margin: auto;
        padding: 0.5rem 0rem 0rem;
    }

    .dashboard-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 225px));
    }

    .dashboard-table {
        padding: 1rem 0rem;
    }
}

@media only screen and (max-width: 992px) {
    .dashboard-grid {
        grid-template-columns: repeat(auto-fit, minmax(225px, 200px));
    }

    .dashboard-title {
        display: none;
    }

    .dashboard-container {
        padding: 1rem 2rem 0rem;
    }
}

@media only screen and (max-width: 768px) {
    .dashboard-grid {
        grid-template-columns: repeat(auto-fit, minmax(215px, 195px));
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-container {
        padding: 0rem 1rem 0rem;
    }

    .dashboard-grid {
        padding: 1.5rem 0rem;
    }

    .dashboard-list-container {
        font-size: 11px;
        margin: 1rem 0rem;
        padding: 7px 7px;
    }
}

@media only screen and (max-width: 500px) {
    .dashboard-list-container {
        padding: 15px 5px;
        font-size: 10px;
    }

    .dashboard-card > svg {
        width: 40px;
        height: 40px;
    }

    .dashboard-card > div > h4 {
        font-weight: 600;
        font-size: 16px;
    }

    .dashboard-card > div > p {
        font-size: 18px;
    }
    
    .dashboard-grid {
        grid-template-columns: repeat(auto-fit, minmax(165px, 172px));
        gap: 14px;
    }

    td {
        padding: 1rem 0.25rem !important;
        font-weight: 600;
    }
}
