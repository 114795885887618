/* .home-container {
    padding: 1rem 6rem;
} */

.logo-container {
    padding: 1rem 10rem 0rem;
}

.image-container {
    padding: 3rem 0rem 0rem !important;
}

.image-container > div {
    padding: 0rem 0rem 2rem;
}

.image-container > div > h4 {
    font-size: 32px;
}

.image-container > div > p {
    padding-top: 1.5rem;
}

.pc-form-container {
    padding: 3rem 0rem;
}

.pc-form-container > div > h4 {
    color: #0E0842;
}

.pc-form-container > div > p {
    color: #5C5C80;
}

.pc-form-container > div {
    text-align: center;
    padding: 1rem;
}

.pc-form-container > div > p {
    font-size: 16px;
}

.pc-form-container > form > div > label {
    display: block;
    color: rgba(33, 150, 83, 1);
    font-weight: 600;
    padding: 0.5rem 0rem;
}

.pc-form-container > form > div > input {
    width: -webkit-fill-available;
    border: 1px solid rgba(228, 228, 231, 1);
    background-color: #E8F0FE;
    border-radius: 4px;
    height: 40px;
    font-weight: 600;
    padding: 0.5rem;
}

.pc-form-container > form > div > input:focus-visible, .login-password > input:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.pc-form-container > form > div {
    padding: 0.5rem 0rem;
}

.reset-password-title > h4 {
    font-size: 3rem;
}

.login-button > button {
    border: 0px;
    width: -webkit-fill-available;
    background-color: #219653;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    height: 40px;
    border-radius: 4px;
    margin-top: 1.5rem;
}

.login-password {
    width: -webkit-fill-available;
    border: 1px solid rgba(228, 228, 231, 1);
    background-color: #E8F0FE;
    border-radius: 4px;
    height: auto;
}

.errormessage-ab {
    font-size: 12px;
    color: red;
    padding-top: 0.5rem;
    margin-bottom: 0rem;
    font-style: italic;
}

.login-password > input {
    width: 93%;
    height: 40px;
    border: 0px;
    background-color: #E8F0FE;
    overflow: hidden;
    padding: 0.5rem;
    font-weight: 600;
}

.forgot-password {
    text-align: end;
    display: flex;
    justify-content: space-between;
}

.forgot-password > a {
    text-decoration: none;
    color: #219653;
}

.forgot-password > a:hover {
    color: #219653;
    text-decoration: underline;
}

@media only screen and (max-width: 1280px) {
    .pc-form-container {
        padding: 6rem 0rem;
    }
}

@media only screen and (max-width: 1024px) {
    .logo-container {
        padding: 1rem 2rem 0rem;
    }
}

@media only screen and (max-width: 992px) {
    .image-container {
        display: none;
    }

    .logo-container {
        padding: 1rem 2rem 0rem;
    }

    .pc-form-container {
        padding: 4rem 0rem;
    }
}

@media only screen and (max-width: 768px) {
    .pc-form-container {
        padding: 3rem 0rem;
    }
}

@media only screen and (max-width: 600px) {
    .logo-container {
        padding: 1rem 1rem 0rem;
    }
}

@media only screen and (max-width: 500px) {
    .pc-form-container {
        padding: 2rem 0rem;
    }
}