.sidebarcontainer {
    display: flex;
    height: 100vh;
    position: fixed;
    overflow: auto;
}

.shortname {
    border-radius: 50%;
    background-color: gray;
    font-size: 18px;
    font-weight: 700;
    color: white;
    letter-spacing: 2px;
    height: 45px;
    width: 45px;
    align-content: center;
    display: grid;
    margin: auto;
    justify-content: center;
}

.sidebaricons {
    text-align: center;
    padding: 1.25rem 0rem;
    display: block;
    text-decoration: none;
}

.sideicon-tag {
    font-size: 0.75rem;
    padding-top: 0.5rem;
    margin: 0rem;
    color: white;
    text-decoration: none;
}

.activesidebar > .sideicon-tag, .activesidebar-opt > .sideicon-tag {
    color: rgb(19, 27, 71);
}

.sidebaricons > svg {
    fill: white;
}

.sidebaricons > svg > g > path {
    fill: white;
}

.sidebaricons > svg > rect {
    fill: white;
}

.sidebaricons-opt > svg > path {
    fill: white;
}

.activesidebar-opt > svg > path {
    fill: rgb(19, 27, 71);
}

.sideicons-container {
    background-color: #1CB16B;
    padding: 0.75rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    height: 100vh;
}

.sideicon-list {
    padding: 4rem 0rem;

}

.arr-right{
    margin: auto;
}

.arr-right > svg > path {
    fill: white;
}

.sidebarlist-container {
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.sidebarlist {
    width: 80%;
    padding: 7.75rem 1rem;
}

.sidebar-name > a {
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.sidebar-name {
    padding: 1.3rem 0rem;
}

.activesidebar > svg {
    fill: rgb(19, 27, 71);
}

.sidebar-name > a:hover {
    color: rgb(19, 27, 71);
}

.activesidebarname > a {
    color: rgb(19, 27, 71);
}

.mobile-sidebar {
    background-color: #1CB16B !important;
}

@media only screen and (max-width: 1280px) {
    .sidebar-name {
        padding: 1.28rem 0rem;
    }
}

@media only screen and (max-width: 1024px) {
    .sideicon-list {
        padding: 2rem 0rem;
    }

    .sidebarlist {
        padding: 5.75rem 1rem;
    }

    .sidebaricons > svg {
        width: 22px;
        height: 22px;
    }

    .sidebaricons {
        padding: 0.75rem 0rem;
    }

    .sideicon-tag {
        padding-top: 0.25rem;
    }

    .customer-modal-body {
        padding: 2rem 2rem;
    }

    .open > .maincontent > .customer-container {
        padding: 4rem 2rem 0rem;
    }

    .sidebar-name {
        padding: 0.75rem 0rem;
    }
}

@media only screen and (max-width: 992px) {
    .open > .maincontent > .customer-container {
        padding: 4rem 1rem 0rem;
    }

    .sidebarcontainer {
        display: none;
    }

    .sidebaricons {
        display: flex;
        color: white;
        gap: 1rem;
        padding: 1.5rem 2rem;
    }

    .sidebaricons > h4 {
        margin: 0px;
    }

    .sidebaricons:hover {
        color: rgb(19, 27, 71);
    }

    .activesidebar-opt > h4, .activesidebar > h4 {
        color: rgb(19, 27, 71);
    }

    .btn-close {
        color: #ffffff !important;
        background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat !important;
    }
}

@media only screen and (max-width: 820px) {
   
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .sidebarcontainer {
        display: none;
    }

    .offcanvas {
        --bs-offcanvas-width: 250px !important;
    }

    .sidebaricons > h4 {
        margin: 0px;
        font-size: 19px;
    }

    .sidebaricons {
        padding: 1rem 2rem;
    }

    .lists-cust {
        font-size: 11px;
    }
}

@media only screen and (max-width: 500px) {
 
}