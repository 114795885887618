.profile-container {
    padding: 4rem;
}

.profile-display-group {
    display: flex;
    gap: 2rem;
    padding: 1rem 0rem;
}

.profile-display-group > div > label, .reset-password-form > div > label  {
    display: block;
    padding-bottom: 0.75rem;
}

.profile-display-group > div > input, .reset-password-form > div > input  {
    border: 0px;
    background-color: #e2f5ec;
    border-radius: 4px;
    height: 40px;
    width: 17rem;
    padding: 0px 10px;
    font-weight: 600;
}

.profile-display-group > div > input:focus-visible, .reset-password-form > div > input:focus-visible, .passwordgroup > input:focus-visible  {
    outline: 0;
}

.profile-welcome > h4 {
    font-weight: 700;
}

.profile-info-container > h4 {
    font-weight: 700;
    font-size: 20px;
    padding: 0.5rem 0rem;
}

.profile-display-container {
    border-bottom: 1px solid #DDDD;
    padding-bottom: 2.5rem;
}

.reset-password-button {
    border: 0px;
    border-radius: 4px;
    background-color: rgb(19, 27, 71);
    display: inline;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 25px;
    margin: 2rem 0rem;
    z-index: 99;
    position: sticky;
}

.reset-password-container {
    padding: 1.5rem 0rem;
}

.reset-password-container > h4 {
    font-weight: 700;
}

.reset-password-form > div {
    padding: 0.5rem 0rem;
}

.passwordgroup {
    width: 17rem;
    background-color: #e2f5ec;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.passwordgroup > input {
    width: 90%;
    border: 0px;
    background-color: inherit;
    height: 40px;
    padding: 0px 10px;
    font-weight: 600;
    border-top-left-radius: 4px;
    /* border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; */
    border-bottom-left-radius: 4px
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {
    .profile-container {
        padding: 4rem 2rem;
    }
    
    .profile-display-group {
        padding: 0.5rem 0rem;
    }    
}

@media only screen and (max-width: 992px) {
    .profile-container {
        padding: 2rem 2rem;
    }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .profile-display-group {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        padding: 1rem 0rem 0rem;
    }
    .profile-container {
        padding: 0rem 2rem;
    }
}

@media only screen and (max-width: 500px) {
    .profile-container {
        padding: 0.5rem 2rem;
    }
}