.cancelbody > h6 {
 font-weight: 700;
 font-size: 24px;
 padding: 0rem 0rem 1rem;
}

.emailsent {
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
}
.cancelbody {
    padding: 15px;
}

.cancel-radio {
    padding: 0rem 0rem 1rem;
}

.cancel-radio > label {
    font-weight: 600 !important;
    font-size: 15px !important;
}

.cancelbody > div > textarea {
    background-color: #e2f5ec;
    border: 0;
    border-radius: 4px;
    padding: 10px 10px;
    width: -webkit-fill-available;
}

.cancelbody > div > textarea:focus-visible {
    outline: 0px;
}

.cancelbody > div > .typereason{
    display: block;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 10px;
}

.initiate-cancel-button {
    background-color: #EF4444;
    color: white;
    padding: 10px 0px;
    text-align: center;
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
}

.initiate-cancel-button > p {
    margin: 0px;
}