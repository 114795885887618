.policy-container {
    padding: 4rem 4rem 0rem;
    position: relative;
}

.policy-tab-container {
    box-shadow: 0px 4px 15px rgba(11, 34, 57, 0.13);
    border-radius: 8px;
    /* margin: 4rem 0rem; */
    padding: 0.75rem;
}

.policy-card-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
    padding: 1rem 0rem;
}

.policies-card-container {
    padding: 3rem 0rem 0rem;
}

.navigate-back {
    padding: 2rem 4rem 0rem;
}

.admin-policy-card-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(260px, 260px));
    padding: 4rem 0rem;
    position: relative;
}

.admin-policy-card {
    display: flex;
    justify-content: space-around;
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    padding: 2rem 1.5rem;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    text-align: center;
}

.admin-policy-card > div > h4 {
    font-size: 18px;
}

.admin-policy-card > div > svg {
    width: 80px;
    height: 80px;
    margin: 15px 0px 0px;
}

.admin-policy-card > div > p {
    margin: 0;
    padding: 35px 15px;
}

.admin-policy-button {
    background-color: white;
    padding: 10px 15px;
    display: block;
    border-radius: 8px;
    color: rgba(34, 197, 94, 1);
    font-weight: 700;
    border: 1px solid rgba(228, 228, 231, 1);
    text-decoration: none;
}

.admin-policy-button:hover {
   color: rgba(34, 197, 94, 1);
}

.manage-policy-button:hover {
    color: white;
 }

.admin-policy-card:hover {
    color: black;
}

.manage-policy-button {
    background-color: rgba(34, 197, 94, 1);
    margin: 1rem 0rem;
    color: white;
}

.nonactive-policy-card > div > h4, .nonactive-policy-card > div > span, .nonactive-policy-card > svg, .nonactive-policy-card > div > svg, .nonactive-policy-card > div > p {
    opacity: 0.3;
    cursor: none;
}

.buy-policy-but::after {
    content: none !important;
}

.nonactive-policy-card {
    cursor: default !important;
}

.nonactive-policy-card:hover {
    border: 0px !important;
}

.healthdropdown {
    cursor: pointer;
}

.policy-card {
    display: flex;
    justify-content: space-around;
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    padding: 1.5rem 0px;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.policy-card:hover {
    border: 1px solid #1fb16b;
    color: inherit;
}

.policy-card > div {
    position: relative;
}

.policy-card > div > h4 {
    align-self: center;
}

.policy-card > div > span {
    color: rgb(19, 27, 71);
    text-decoration: underline;
    position: absolute;
    bottom: 0;
    font-size: 13px;
}

.policy-tab-title {
    display: flex;
    justify-content: center;
    gap: 20px;
    border-bottom: 1px solid #DDDDDD;
}

.policy-tab-title > div {
    margin: 0rem;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 10px 10px;
    cursor: pointer;
}

.policy-tab-body > div {
    padding: 2.5rem 4rem;
}

.policy-search {
    text-align: end;
    width: 315px;
    display: flex;
    margin-left: auto;
    padding-bottom: 25px;
}

.policy-search > input {
    width: 85%;
    border: 1px solid #DDDDDD;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 35px;
}

.policy-search > div {
    background-color: rgb(19, 27, 71);
    color: white;
    font-weight: 700;
    padding: 5px 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.vehicle-policy-container {
    padding: 2rem 4rem;
    /* z-index: 999; */
    position: relative;
}

.paginatecontainer {
    display: flex;
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
    justify-content: flex-end;
    padding: 1rem 0rem;
    z-index: 99;
    position: relative;
}

.pageli, .breakli {
    padding: 4px 11px;
    font-size: 14px;
    font-weight: 700;
    color: #7F8D96;
}

.prevbtn > svg, .nextbtn > svg {
    /* padding: 10px 15px; */
    font-size: 27px;
    font-weight: 700;
    text-decoration: none;
    color: inherit;
    fill: #0B2239;
    stroke: #0B2239;
}

.prevbtn:hover, .nextbtn:hover {
    /* padding: 10px 15px; */
    color: inherit;
}


.previous, .next {
    margin: -2px 0px;
    color: #0B2239;
    padding: 4px 11px;
}

.paginationactive {
    background-color: #1fb16b;
    box-shadow: 0px 4px 15px rgba(17, 130, 128, 0.14);
    border-radius: 6px;
    color: #F8ECD7;
}

.paginationdisabled > .prevbtn > svg, .paginationdisabled > .nextbtn > svg{
    fill: #7F8D96;
    stroke: #7F8D96;
}

.policy-search-group {
    width: 485px;
}

.policy-search-group > input, .policy-search-group > select {
    width: 50%;
    border: 1px solid #DDDDDD;
}

@media only screen and (max-width: 1280px) {
    .policy-tab-body > div {
        padding: 2.5rem 2rem;
    }

    .open > .maincontent > .policy-container > .policy-tab-container {
        margin: 4rem 1rem;
    }
}

@media only screen and (max-width: 1024px) {
    .policy-tab-container {
        margin: 4rem 1rem;
    }

    .open > .maincontent > .policy-container {
        padding: 4rem 1rem 0rem;
    }

    .policy-tab-body > div {
        padding: 2.5rem 1rem;
    }

    .policy-card-container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
        padding: 0.75rem 0rem;
    }

    .policy-card > div > span {
        position: inherit;
        padding-top: 0.5rem;
    }

    .policy-card {
        padding: 1rem 10px;
    }

    .policy-container {
        padding: 4rem 2rem 0rem;
    }

    .policies-card-container {
        padding: 1rem 0rem 0rem;
    }

    .policy-title {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 600;
            color: #22C55E;
    }

    .policy-card > svg {
        width: 50px;
        height: 50px;
    }

    .policy-card > div > h4 {
        font-size: 22px;
    }

    .vehicle-policy-container {
        padding: 2rem 2rem;
    }

    .policy-tab-container {
        margin: 0rem;
        font-size: 14px;
    }

    .navigate-back {
        padding: 2rem 2rem 0rem;
    }
}

@media only screen and (max-width: 992px) {
    .vehicle-policy-container {
        padding: 2rem 2rem;
    }

    .navigate-back {
        padding: 1rem 2rem 0rem;
    }

    .policy-tab-container {
        margin: 0.5rem 0rem;
    }

    .policy-card-container {
        grid-template-columns: repeat(auto-fit, minmax(225px, 245px));
        padding: 0.75rem 0rem;
    }

    .policy-title {
        display: block;
    }

    .policy-container {
        padding: 1rem 2rem 0rem;
    }
}

@media only screen and (max-width: 820px) {
    .admin-policy-card-container {
        display: grid;
        gap: 5rem;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, minmax(285px, 260px));
    }

    .policy-tab-container {
        overflow: auto;
    }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .policy-container {
        padding: 0rem 1rem 0rem;
    }

    .policy-tab-title > div {
        margin: 0rem;
        font-size: 15px;
    }
    
    .policy-tab-container {
        margin: 0rem 0rem;
        font-size: 11px;
    }

    .policy-tab-body > div {
        padding: 2.5rem 0.5rem;
    }

    .policy-underwriter {
        display: none;
    }

    .policy-card-container {
        grid-template-columns: repeat(auto-fit, minmax(225px, 230px));
        padding: 0.75rem 0rem;
        gap: 1rem;
    }

    .policy-card > div > h4 {
        font-size: 18px;
    }

    .policy-card > div > span {
        font-size: 11px;
    }

    .vehicle-policy-container {
        padding: 0rem 1rem;
    }

    .navigate-back {
        padding: 0rem 1rem 0rem;
    }
}

@media only screen and (max-width: 500px) {
    .policy-search {
        text-align: end;
        width: 235px;
    }

    .customer-list > thead, .customer-list > tbody, .customer-list > tbody > tr > td, .customer-list > thead > tr > th, .customer-list > thead, .customer-list > thead > tr, .customer-list > tbody, .customer-list > tbody > tr {
        border-width: 1px;
        padding: 4px !important;
        font-size: 10px;
    }

    .policy-search > input {
        height: 30px;
    }

    .policy-search > div {
        font-size: 13px;
    }

    .policy-card > div > h4 {
        font-size: 16px;
    }

    .policy-card-container {
        grid-template-columns: repeat(auto-fit, minmax(172px, 170px));
        padding: 0.75rem 0rem;
        gap: 0.75rem;
    }

    .vehicle-policy-container {
        padding: 2rem 0.5rem;
        overflow: auto;
        margin: 0rem 0.5rem;
    }

    /* .policy-tab-container {
        width: max-content;
    } */

    .buy-vehicle-box {
        width: auto;
    }

    .buy-form {
        padding: 0.75rem;
    }

    .policy-tab-container { 
        overflow: auto;
    }
}