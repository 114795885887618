
.searchselect {
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: .375rem;
}
.searchselect:focus-visible {
    outline: none;
}

.dropdownhoverbutton {
    background-color: white;
}

.dropdownhoverbutton:hover {
    background-color: rgb(170 174 182 / 0.25);
}

.searchinputCustomer {
    border: 0px;
    background-color: #e2f5ec;
    /*border-radius: 4px;*/
    height: 40px;
    color: black;
    width: -webkit-fill-available;
    padding: 0px 10px;
    font-weight: 600;
}

.searchinputCustomer:focus-visible{
    outline: none;
}
