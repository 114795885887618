.delete-button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.delete-header {
    justify-content: center;
}

.delete-body > p {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.delete-button-container > button {
    border: 0px;
    border-radius: 4px;
    padding: 7px 20px;
    background-color: #0d6efd;
    color: white;
    font-weight: 700;
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {
 
}

@media only screen and (max-width: 992px) {
    
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 500px) {
 
}